input {
  cursor: pointer;
}

.input {
  margin-bottom: 1rem;

  &--tag {
    box-sizing: border-box;

    padding: 1rem 1.2rem;

    border: 1px solid var(--color-grey-100);
    border-radius: 2.5rem;

    margin: 0.5rem 0;

    outline: none;

    &::placeholder {
      color: var(--color-grey-100);
    }

    &:focus {
      transition: 150ms;
      border: 2px solid var(--color-blue-100) !important;
      outline: none;
      margin: calc(0.5rem - 1px) 0;

      box-sizing: border-box;
    }

    &--password {
      padding: 1rem 2.6rem 1rem 1.2rem;
    }
  }

  &--tag-thin {
    padding: 0.8rem 1.2rem;

    border: 1px solid var(--color-grey-100);
    border-radius: 2.5rem;

    margin: 0.5rem 0rem 1rem 0rem;

    outline: none;

    text-align: start !important;

    &::placeholder {
      color: var(--color-grey-100);
    }
  }

  &--label {
    position: relative;

    display: flex;
    flex-direction: column;

    text-align: start;

    width: 100%;

    color: var(--color-brown-100);
  }

  &--select {
    position: relative;
    width: 100%;
    z-index: 1;

    &:before {
      display: block;

      position: absolute;
      top: 0;
      right: 1rem;

      z-index: -1;
    }
  }

  &--password {
    position: absolute;
    right: 1rem;
    bottom: 1.2rem;

    img {
      width: 1.5rem;
      height: 1.5rem;

      transform: translateY(0.2rem);
    }
  }

  &--alert {
    display: flex;
    align-items: center;

    font-size: 0.8rem;

    margin: 0 0 1rem 1rem;

    span {
      padding: 0 !important;
      color: var(--color-red-100) !important;
    }

    &--rad-8 {
      border-radius: 0.5rem;
    }

    &--label {
      position: relative;

      display: flex;
      flex-direction: column;

      text-align: start;

      width: 100%;
    }

    &--icon {
      width: 1rem;
      margin-right: 0.5rem !important;
    }
  }

  &--rad-8 {
    border-radius: 0.5rem;
  }
}

.invalid {
  border: 2px solid var(--color-red-100);
}
