@keyframes fadeInTop {
    0% {
        opacity: 0;
        transform: scale(0);
        transform-origin: top;
    }
    100% {
        opacity: 1;
        transform: scale(1);
        transform-origin: top;
    }
}

@keyframes fadeInTopRight {
    0% {
        opacity: 0;
        transform: scale(0);
        transform-origin: top right;
    }

    100% {
        opacity: 1;
        transform: scale(1);
        transform-origin: top right;
    }
}

@keyframes fadeInTopLeft {
    0% {
        opacity: 0;
        transform: scale(0);
        transform-origin: top left;
    }

    100% {
        opacity: 1;
        transform: scale(1);
        transform-origin: top left;
    }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInWidth {
  0%{
    opacity: .6;
    width: .8rem;
  }

  100%{
    opacity: 1;
    width: 1.8rem;
  }
}

@keyframes fadeOutWidth {
  0%{
    opacity: 1;
    width: 1.8rem;
  }

  100%{
    opacity: .6;
    width: .8rem;
  }
}
