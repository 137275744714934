:root {
    --color-white: #FFFFFF;
    --color-white-50: #F5F5F5;
    --color-white-100: #F8F8F8;
    --color-white-150: #F2F2F2;
    --color-white-200: #E8EAED;
    --color-white-250: #D4D4D4;
    --color-orange-200: #F79F25;
    --color-orange-300: #ED9924;
    --color-blue-000: #CED6E5;
    --color-blue-100: #93B9D1;
    --color-blue-200: #3483FA;
    --color-blue-300: #0063A1;
    --color-black: #000000;
    --color-black-500: #1A1818;
    --color-grey-100: #C6C4C4;
    --color-grey-150: #B7B7B7;
    --color-grey-200: #848484;
    --color-grey-300: #59585D;
    --color-grey-400: #4E4E4E;
    --color-grey-500: #3D3C44;
    --color-grey-600: #282933;
    --color-brown-100: #59585D;
    --color-brown-300: #4B4242;
    --color-brown-400:  #322B2B;
    --color-brown-450: #343023;
    --color-brown-500: #292020;
    --color-green-000: #DBE2D2;
    --color-green-100: #7ED321;
    --color-green-200: #53D258;
    --color-red-100: #D76363;
    --color-red-200: #C63636;
    --color-yellow-100: #EDE2AB;
}
