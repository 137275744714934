@font-face {
    font-family: "IBMPlexSans Bold";
    src: url(/assets/fonts/IBMPlexSans-Bold.ttf) format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "IBMPlexSans BoldItalic";
    src: url(/assets/fonts/IBMPlexSans-BoldItalic.ttf) format("truetype");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "IBMPlexSans ExtraLight";
    src: url(/assets/fonts/IBMPlexSans-ExtraLight.ttf) format("truetype");
    font-weight: 200;
}

@font-face {
    font-family: "IBMPlexSans ExtraLightItalic";
    src: url(/assets/fonts/IBMPlexSans-ExtraLightItalic.ttf) format("truetype");
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: "IBMPlexSans Italic";
    src: url(/assets/fonts/IBMPlexSans-Italic.ttf) format("truetype");
    font-style: italic;
}

@font-face {
    font-family: "IBMPlexSans Light";
    src: url(/assets/fonts/IBMPlexSans-Light.ttf) format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: "IBMPlexSans LightItalic";
    src: url(/assets/fonts/IBMPlexSans-LightItalic.ttf) format("truetype");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "IBMPlexSans Regular";
    src: url(/assets/fonts/IBMPlexSans-Regular.ttf) format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: "IBMPlexSans SemiBold";
    src: url(/assets/fonts/IBMPlexSans-SemiBold.ttf) format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: "IBMPlexSans SemiBoldItalic";
    src: url(/assets/fonts/IBMPlexSans-SemiBoldItalic.ttf) format("truetype");
    font-weight: 600;
    font-style: italic;
}


